<template>
  <section class="site-price">
    <div class="site-price__wrapper">
      <div class="site-price__wrapper__text">
        <h2 class="site-price__wrapper__text__title site-section-title">
          Combien ça coûte?
        </h2>
        <div class="site-price__wrapper__text__subtitle">
          Accédez aux modules CRA et Legal gratuitement et sans limitation de durée!
        </div>
        <div class="site-price__wrapper__text__subtitle">
          Pour accéder au module de Facturation et générer vos factures en deux clics, optez pour l'Offre Facturation. Testez l'offre gratuitement pendant trois mois.
        </div>
      </div>
      <div class="site-price__wrapper__prices">
        <div class="site-price__wrapper__prices__card">
          <div class="site-price__wrapper__prices__card__title">Offre CRA</div>
          <div class="site-price__wrapper__prices__card__price">
            <span class="site-price__wrapper__prices__card__price__amount">Gratuit</span>
          </div>
          <ul class="site-price__wrapper__prices__card__features">
            
            <li class="site-price__wrapper__prices__card__features__feature site-price__wrapper__prices__card__features__feature--title">
              Compte Rendu d'Activité (CRA)
            </li>
            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              CRA autovalidé
            </li>
            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              CRA spécifique à l'ESN
            </li>

            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              validation du CRA par le client final
            </li>

            <li class="site-price__wrapper__prices__card__features__feature site-price__wrapper__prices__card__features__feature--title">
              Général et reporting
            </li>
            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Clients et contrats illimités
            </li>
            <li class="site-price__wrapper__prices__card__features__feature">
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Coffre fort RC Pro, Insee, Kbis, attestation de vigilance
            </li>
            <li class="site-price__wrapper__prices__card__features__feature">
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Informations légales
            </li>
          </ul>
          <div class="site-price__wrapper__prices__card__separator" />
          <div class="site-price__wrapper__prices__card__action">
            <SiteButton
              type="primary"
              label="Démarrer"
              has-arrow
              full-width-mobile
              @click="emit('signup', 'cra')"
            />
          </div>
        </div>
        <div class="site-price__wrapper__prices__card site-price__wrapper__prices__card--invert">
          <div class="site-price__wrapper__prices__card__title">Offre Facturation</div>
          <div class="site-price__wrapper__prices__card__price">
            <span class="site-price__wrapper__prices__card__price__amount">7,99 € </span>
            <span class="site-price__wrapper__prices__card__price__per">HT / mois</span>
            <div class="site-price__wrapper__prices__card__pre-action">
              3 mois gratuits
            </div>

          </div>
          <ul class="site-price__wrapper__prices__card__features">
            <li class="site-price__wrapper__prices__card__features__feature site-price__wrapper__prices__card__features__feature--reprise">
              Fonctionnalités de l'Offre CRA plus :
            </li>
            <li class="site-price__wrapper__prices__card__features__feature site-price__wrapper__prices__card__features__feature--title">
              Compte Rendu d'Activité (CRA)
            </li>

 
            <!-- <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Ou télétransmission des temps à l'ESN <span>quand c'est possible</span>
            </li>-->
            <!-- <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Transmission auto des temps auprès de l'ESN
            </li> -->
            
            <li class="site-price__wrapper__prices__card__features__feature site-price__wrapper__prices__card__features__feature--title">
              Facture
            </li>

            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Facture relative à un CRA en deux clics
            </li>

            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Factures et avoir illimités
            </li>

            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Envoi de facture par mail
            </li>
            <li class="site-price__wrapper__prices__card__features__feature site-price__wrapper__prices__card__features__feature--title">
              Général et reporting
            </li>
            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Donner l'accès en lecture à mon comptable
            </li>

          </ul>
          <div class="site-price__wrapper__prices__card__separator" />

          <div class="site-price__wrapper__prices__card__action">
            <SiteButton
              type="primary"
              label="Tester"
              has-arrow
              full-width-mobile
              @click="emit('signup', 'facture')"
            />
          </div>
        </div>
        <!-- <div class="site-price__wrapper__prices__card">
          <div class="site-price__wrapper__prices__card__title">Micro entreprise</div>
          <div class="site-price__wrapper__prices__card__price">
            <span class="site-price__wrapper__prices__card__price__amount">14,99 €</span>
            <span class="site-price__wrapper__prices__card__price__per"> HT / mois</span>
          </div>
          <ul class="site-price__wrapper__prices__card__features">
            <li class="site-price__wrapper__prices__card__features__feature site-price__wrapper__prices__card__features__feature--title">
              Général
            </li>
            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Factures fournisseur et suivi de la TVA
            </li>

            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Alertes seuil chiffre d'affaire et TVA
            </li>
            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Suivi du Chiffre d'Affaire
            </li>
            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Déclarer mon Chiffre d'Affaire à l'URSSAF
            </li>

            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Envoyer les mails à partir de ma boîte
            </li>

            <li class="site-price__wrapper__prices__card__features__feature site-price__wrapper__prices__card__features__feature--title">
              Compte Rendu d'Activité (CRA)
            </li>
            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Validation du CRA par le client final
            </li>
            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              CRA spécifique à l'ESN cliente
            </li>
            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Transmission auto des temps auprès de l'ESN
            </li>
            
            <li class="site-price__wrapper__prices__card__features__feature site-price__wrapper__prices__card__features__feature--title">
              Facture
            </li>

            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Désactiver le filigrane sur la facture
            </li>  
            <li
              class="site-price__wrapper__prices__card__features__feature"
            >
              <check class="site-price__wrapper__prices__card__features__feature__puce" />
              Détection automatique du paiement (synchro compte bancaire)
            </li>         
          </ul>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script setup>

  import check from './check.vue'
  import cross from './cross.vue'

  const emit = defineEmits(['signup'])

</script>

<style lang="scss">

  $desktop-breakpoint: 990px;
  $tablet-breakpoint: 672px;

  
  .site-price {
    display: flex;
    justify-content: center;
    &__wrapper {
      max-width: 1260px;
      display: flex;
      flex-direction: column;
      margin: 40px 15px;

      @media screen and (min-width: $desktop-breakpoint) {
        flex-direction: row;
        margin: 75px 15px 100px 15px;
      }

      &__text {
        @media screen and (min-width: $tablet-breakpoint) {
          margin: 20px;
        }
        @media screen and (min-width: $desktop-breakpoint) {
          max-width: 30%;
          margin-top: 10%;
          padding-right: 15px;
        }
        @media screen and (min-width: 1100px) {
          max-width: 40%;
        }
        &__title {
          color: #01498B;
          margin-bottom: 20px;

          @media screen and (min-width: $tablet-breakpoint) {
            text-align: center;
          }
          @media screen and (min-width: $desktop-breakpoint) {
            text-align: left;
          }
        }

        &__subtitle {
          @media screen and (min-width: $tablet-breakpoint) {
            text-align: center;
          }
          @media screen and (min-width: $desktop-breakpoint) {
            text-align: left;
          }
          font-size: 18px;
          color: #242424;
          line-height: 26px;
          font-weight: 200;
          margin-bottom: 5px;
        }
      }

      &__prices {
        @media screen and (min-width: $desktop-breakpoint) {
          padding-left: 15px;
        }
        margin-left: -15px;
        margin-right: -15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;

        @media screen and (min-width: $desktop-breakpoint) {
          margin-left: 0;
          margin-right: 0;
          justify-content: flex-end;
          flex-wrap: nowrap;
        }

        &__card {
          display: flex;
          flex-direction: column;
          margin: 25px 15px 0 15px;
          padding: 30px 20px;
          border-radius: 20px;
          border: solid 2px #01498B;
          max-width: 345px;

          flex-grow: 1;
          flex-shrink: 1;

          @media screen and (min-width: $desktop-breakpoint) {
            width: 50%;
          }

          &__title {
            font-size: 26px;
            color: #01498B;
            margin-bottom: 5px;
          }

          &__price {
            margin-bottom: 30px;
            color: #01498B;
            &__amount {
              font-size: 40px;
              font-weight: 600;
            }

            &__per {
              font-size: 21px;
              font-weight: 200;
            }
          }

          &__features {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            &__feature {
              color: #01498B;
              font-size: 18px;
              font-weight: 200;
              margin-bottom: 20px;
              display: inline;
              position: relative;
              margin-left: 32px;

              &--reprise {
                margin-left: 0;
                font-size: 20px;
                font-weight: 400;
                margin-top: -10px;
                margin-bottom: 30px;
              }

              &--title {
                font-weight: 700;
                font-size: 20px;
                display: none;
              }

              &__puce {
                position: absolute;
                left: -32px;
                flex-shrink: 0;
                fill: #01498B;
              }

              span {
                font-size: 12px;
              }
            }
          }

          &__separator {
            flex-grow: 10;
          }

          &__pre-action {
            font-weight: 200;
            color: #01498B;
          }

          &__action {
            margin-top: 10px;

          }


          &--invert {
            background-color: #01498B;
            box-shadow: 0 4px 20px 0 rgb(0 0 0 / 0.2);

            .site-price__wrapper__prices__card {

              &__title {
                color: white;
              }
              &__price {
                color: white;
              }

              &__features {
                &__feature {
                  color: white;
                  &__puce {
                    fill: white;
                  }
                }
              }

              &__pre-action {
                color: white;
              }
            }
          }


        }
      }
    }
  }
</style>