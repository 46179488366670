<template>
  <div class="site-features-feature">

    <div class="site-features-feature__content">
      <div class="site-features-feature__content__icon">
        <img :src="`/features/${props.icon}.svg`" :alt="`Image de ${props.icon}`">
      </div>
      <div class="site-features-feature__content__title">
        {{ props.title }}
      </div>
      <div class="site-features-feature__content__text">
        {{ props.text }}
      </div>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  })
</script>

<style lang="scss">

  $desktop-breakpoint: 990px;
  $tablet-breakpoint: 672px;

  .site-features-feature {
    margin-top: 45px;
    display: flex;
    align-items: stretch;

    &__content {
      border: 2px solid #01498B;
      border-radius: 10px;
      position: relative;
      padding: 40px 20px;

      &__icon {
        background-color: #01498B;
        height: 60px;
        width: 60px;
        border-radius: 30px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 36px;
          height: 36px;
        }
      }

      &__title {
        font-size: 22px;
        font-weight: 600;
        color: #01498B;
        text-align: center;
        margin-bottom: 10px;
      }
      &__text {
        font-size: 18px;
        font-weight: 200;
        line-height: 26px;
        text-align: center;

        @media screen and (min-width: $desktop-breakpoint) {
          font-weight: 300;
        }
        
      }
    }
  }
</style>