<template>
  <div />
</template>

<script setup>
  import useSiteIdentity from '@/components/SiteIdentity'

  setTimeout(async () => {
    try {
      BrevoConversations
    } catch (e) {
      // <-- Brevo Conversations {literal} -->
      (function(d, w, c) {
          w.BrevoConversationsID = '6639c831592fb97fa10ff8a6';
          w[c] = w[c] || function() {
              (w[c].q = w[c].q || []).push(arguments);
          };
          var s = d.createElement('script');
          s.async = true;
          s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
          if (d.head) d.head.appendChild(s);
      })(document, window, 'BrevoConversations');
      // <-- /Brevo Conversations {/literal} -->
    }

    const siteIdentity = useSiteIdentity()

    const handleWidget = () => {
      if (siteIdentity.identity.value) {
        BrevoConversations('updateIntegrationData', {
          email: siteIdentity.identity.value.email,
          firstName: siteIdentity.identity.value.firstname
        })
        BrevoConversations('hide')
      } else {
        BrevoConversations('updateIntegrationData', {
          email: null,
          firstName: null
        })
        BrevoConversations('show')
      }
    }

    handleWidget()
  
    watchEffect(handleWidget)

    onUnmounted(() => {
      BrevoConversations('hide')
    })
  }, 10000)

</script>