<template>
   <svg
      width="13"
      height="13"
      viewBox="0 0 100 100"
      class="puce"
      :class="{
        active: props.active
      }"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="50"
        cy="50"
        r="40"
        fill="#01498B"
      />
      <circle
        cx="50"
        cy="50"
        r="30"
        fill="white"
        class="inner"
      />
    </svg>
</template>

<script setup>
  const props = defineProps({
    active: Boolean
  })
</script>

<style lang="scss" scoped>
  .puce {
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.2s;
    .inner {
      transform-origin: center center;
      transform: scale(1);
      transition: transform 0.2s;
    }
    &.active {
      transform: scale(1.5);
      .inner {
        transform: scale(0.2);
      }
    }
  }
</style>