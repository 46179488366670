<template>
  <section class="site-pitch" ref="root">
    <div class="site-pitch__wrapper">
      <div class="site-pitch__wrapper__illustration">
        <Transition name="fade">
          <Animation v-if="mounted" />
        </Transition>
        
      </div>
      <div class="site-pitch__wrapper__text">
        <h2 class="site-pitch__wrapper__text__title site-section-title">
          Gagnez du temps, ne faites plus d'erreur!
        </h2>
        <div class="site-pitch__wrapper__text__description">
          Tirez parti de l'informatisation de votre activité de freelance IT (Cras, factures, contrats, informations légales) : ne perdez plus de temps et ne faites plus d'erreurs lors de l'établissement de vos CRAs et factures! 
        </div>
        <div class="site-pitch__wrapper__text__action">
          <SiteButton
            label="Tester tout de suite"
            has-arrow
            class="site-pitch__wrapper__text__action__button"
            full-width-mobile    
            @click="emit('signup')"       
          />
        </div>
        <div class="
          site-pitch__wrapper__text__description
          site-pitch__wrapper__text__description--cra-express"
        >
         Vous êtes pressé? Générez votre CRA immédiatement et gratuitement
        </div>
        <div class="site-pitch__wrapper__text__action">
          <SiteButton
            type="secondary"
            label="⚡️ Générer un CRA Express"
            has-arrow
            class="site-pitch__wrapper__text__action__button"
            full-width-mobile
            :href="$router.resolve('cra-express').fullPath"
            @click.prevent="$router.push('cra-express')"       
          />
        </div>
      </div>
    </div>
    <GlobalEvents
      target="window"
      @scroll="appear"
    />
  </section>
</template>

<script setup>
  import SiteButton from '@/components/SiteButton'
  import { GlobalEvents } from 'vue-global-events'
  import debounce from 'lodash.debounce'

  const Animation = defineAsyncComponent(() => import('./Animation'))
  const emit = defineEmits(['signup'])

  const root = ref(null)
  
  const mounted = ref(false)

  const appear = debounce(() => {
    if (!mounted.value) {
      mounted.value = true
    }
  }, 200, {
    maxWait: 200
  })

  onMounted(() => {
    if (root.value.getBoundingClientRect().y - window.innerHeight < 0) {
      mounted.value = true
    }
  })

  setTimeout(() => {
    mounted.value = true
  }, 3000)
</script>

<style lang="scss">
  @use './index.scss';
</style>
